<template>
    <div>
        <el-form ref="queryForm" class="query-box" v-model="queryParams" :inline="true">
            <el-form-item label="时间" label-width="120px">
                <el-date-picker size="small"
                    v-model="createDateRange"
                    type="daterange"
                    range-separator="-"
                    value-format="yyyy-MM-dd"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                    </el-date-picker>
            </el-form-item>
            <el-form-item label="账户类型" label-width="120px">
                <el-select v-model="queryParams.accountType" placeholder="请选择账户类型" size="small" style="width: 240px">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="交易分润" value="POS_PROFIT"></el-option>
                    <el-option label="活动分润" value="ACTIVE_PROFIT"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="业务类型" label-width="120px">
                <el-select v-model="queryParams.changeType" placeholder="请选择业务类型" size="small" style="width: 240px">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="(value,key) in dicts.ACCOUNT_CHANGE_TYPE" :key="value" :label="value" :value="key"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="操作类型" label-width="120px">
                <el-select v-model="queryParams.changeStatus" placeholder="请选择操作类型" size="small" style="width: 240px">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="流入" value="INCOME"></el-option>
                    <el-option label="流出" value="OUTCOME"></el-option>

                </el-select>
            </el-form-item>
            <el-form-item label="渠道" prop="channelNo" label-width="120px">
                <el-select v-model="queryParams.channelNo" placeholder="请选择渠道" style="width: 240px" clearable> 
						<el-option v-for="item in acqChannelList" :label="item.name" :value="item.channelNo" :key="item.channelNo"></el-option>
					</el-select>
            </el-form-item>
            <el-form-item label="备注" label-width="120px">
                <el-input v-model="queryParams.remark"
                          clearable
                          placeholder="请输入备注"
                          style="width: 240px"
                          size="small" @keyup.enter.native="handleQuery"/>
            </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button type="primary" icon="el-icon-search" size="mini"
                           @click="handleQuery" v-permission="'AGENT_ACCOUNT_DETAIL_QUERY'">
                    查询
                </el-button>
                <el-button icon="el-icon-refresh" size="mini"
                           @click="handleResetQuery" v-permission="'AGENT_ACCOUNT_DETAIL_QUERY'">
                    重置
                </el-button>
<!--				<el-button type="primary" icon="el-icon-search" size="mini"-->
<!--						   @click="handleSum" v-permission="'AGENT_ACCOUNT_DETAIL_QUERY'">-->
<!--					汇总-->
<!--				</el-button>-->
                <el-button type="warning" icon="el-icon-download" size="mini"
                           @click="handleExport"
                           v-permission="'AGENT_ACCOUNT_DETAIL_EXPORT'">
                    导出
                </el-button>
            </el-col>
        </el-row>

		<el-row :gutter="10" class="mb8">
			<el-col :span="1.5">
				结算总金额: {{sumData.changeAmount || 0}} 元
			</el-col>
		</el-row>
    </div>
</template>

<script>
    import {ProfitManage} from '@/api';
    import { mapState } from 'vuex'
    export default {
        name: "ToolBar",
        props: {
            dicts: {
                type: Object,
                default: {}
            },
        },
        data() {
            return {
                queryParams: {},
                createDateRange: [],
                updateDateRange: [],
                showCustomForm: false,
				sumData: {},
                acqChannelList:[]
            }
        },
        async mounted() {
			const result = await ProfitManage.acqChannel.listAllChannel();
			this.acqChannelList = result && result.data || [];
        },
        created(){
            console.log(this.dicts)
        },
        computed:{
            ...mapState('app', ['userInfo']),
            isOrg(){
                return this.userInfo.org
            },
        },
        methods: {
            handlerCustomFormOnclose() {
                this.showCustomForm = false;
                this.commitChange();
            },
            handleQuery() {
                this.commitChange();
				this.handleSum();
            },
			async handleSum(){
				let result = await ProfitManage.agentAccountDetail.sum(this.queryParams);
				this.sumData = result.data || {};
			},
            handleResetQuery(){
                this.queryParams = {};
                this.createDateRange = [];
                this.updateDateRange = [];
                this.commitChange();
            },
            handleAdd(){
                this.showCustomForm = true;
            },
            handleExport() {
                this.$confirm("确定导出活动明细",
                        "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(async () => {
                    this.dealWithQueryParams();
                    let result = (await ProfitManage.agentAccountDetail.export(this.queryParams));
                    if (result.success) {
                        this.downloadFile(result.data);
                    }
                });
            },
            dealWithQueryParams() {
                if(!this.createDateRange){
                    this.createDateRange =[]
                }
                let [startCreateTime, endCreateTime] = this.createDateRange;
                this.queryParams.startCreateTime = startCreateTime && (startCreateTime + ' 00:00:00');
                this.queryParams.endCreateTime = endCreateTime && (endCreateTime + ' 23:59:59');

                let [startUpdateTime, endUpdateTime] = this.updateDateRange;
                this.queryParams.startUpdateTime = startUpdateTime && (startUpdateTime + ' 00:00:00');
                this.queryParams.endUpdateTime = endUpdateTime && (endUpdateTime + ' 23:59:59');
            },
            commitChange() {
                this.dealWithQueryParams();
                let params = {...this.queryParams};
                this.$emit('on-change', params);
            },
        }
    }
</script>

<style scoped>

</style>
